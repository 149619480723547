import type { MailboxInfo, MailboxType } from 'owa-client-types';

// Licensing accounts may not have a mailbox associated with the user principal name, this can cause
// requrests to fail. The list of allowed actions and requestUrls is used to make sure that engineers
// are aware that the call they are making will have to work when there is no mailbox associated with
// the licensing account.
const allowedLicensingActions = new Set<string>([
    'GetAccessTokenforResource',
    'getEasidStatusForCloudCacheAccount',
    'copilotupdatedeviceInfo',
]);
const allowedLicensingRequests = new Set<string>([
    'ows/api/HealthCheck/auth',
    'ows/api/beta/PremiumSubscription/GetUserSubscriptionInfo',
    'ows/api/beta/PremiumSubscription/GetPremiumExperiencesandSubsInfo',
]);

/**
 * Checks the action or requestUrl against the allowed set of actions and requestUrls
 * for licensing calls. And returns true if the call is allowed.
 */
export function isAllowedLicensingCall(action?: string, requestUrl?: string): boolean {
    return (
        (!!action && allowedLicensingActions.has(action)) ||
        (!!requestUrl && allowedLicensingRequests.has(requestUrl))
    );
}

const LicensingMailboxType: MailboxType = 'UserPrincipalName';

/**
 * MailboxInfo's that are used for licensing may only have a user identity and not
 * an actual Exchange mailbox that can be accessed. Because of this we have an
 * allowed set of actions that can be used when there is not a mailbox. If the
 * MailboxInfo is a licensing identity, and the action is not supported the caller
 * will raise an alert and throw an error.
 * @param mailboxInfo Optionally specfiy the mailboxInfo to check
 * @param action Optionally specify the action being performed
 * @param requestUrl Optionally specify the URL of the request
 * @returns
 */
export function isDisallowedLicensingCall(
    mailboxInfo?: MailboxInfo,
    action?: string,
    requestUrl?: string
): boolean {
    return mailboxInfo?.type == LicensingMailboxType && !isAllowedLicensingCall(action, requestUrl);
}

import { getQueryStringParameter } from 'owa-querystring';
import { getSessionId } from './getSessionId';
import type { IOfficeFileLinkHandlingInfo } from 'flora-types';
import type { ISeamlessLaunchBrowserUtils } from 'm365-links-types';
import type { IWin32GqlApi } from 'win32gql-types';
import type { ISMimeActiveX } from 'smime-types';

export type ContextMenuRequestedArguments = {
    count: Promise<number>;
    Name: Promise<string>[];
    commandId: Promise<number>[];
    enabled: Promise<boolean>[];
    selectedCommandId: number;
};

export type ContextMenuRequestedArgument = {
    index: number;
    enabled: boolean;
};

type ContextMenuRequestedArgumentsFunction = (args: ContextMenuRequestedArguments) => Promise<void>;

export type ExtendedHost = {
    onContextMenuRequested: (arg0: ContextMenuRequestedArgumentsFunction) => void;
    M365Links: Promise<ISeamlessLaunchBrowserUtils> | undefined;
    FLORA: Promise<IOfficeFileLinkHandlingInfo> | undefined;
    Win32Gql: Promise<IWin32GqlApi | null>;
    SMIME: Promise<ISMimeActiveX> | undefined;
    MonarchExtension: Promise<any> | undefined;
};

export type EnhancedHostProperties = {
    nativeVersion: Promise<string> | undefined;
    sessionId: Promise<string> | undefined;
    deviceId: Promise<string> | undefined;
    extendedHost: ExtendedHost | undefined;
};

type EnhancedHostCachedProperties = {
    nativeVersion: string | undefined;
    sessionId: string | undefined;
    deviceId: string | undefined;
    extendedHost: ExtendedHost | undefined;
};

// if the type is
// null - not implemented like in web
// string - the value in native-host
let cachedEnhancedHost: EnhancedHostCachedProperties = {
    nativeVersion: undefined,
    sessionId: undefined,
    deviceId: undefined,
    extendedHost: undefined,
};

export function getNativeHostVersion(): string {
    return cachedEnhancedHost.nativeVersion || getQueryStringParameter('nativeVersion');
}

export function getEnhancedHostSessionId(): string | undefined {
    return cachedEnhancedHost.sessionId;
}

export function getEnhancedHostDeviceId(): string | undefined {
    return cachedEnhancedHost.deviceId;
}

export function getEnhancedHostExtendedHost(): ExtendedHost | undefined {
    return cachedEnhancedHost.extendedHost;
}

export function initializeEnhancedHost(
    nativeVersion: string | undefined,
    sessionId: string | undefined,
    deviceId: string | undefined,
    extendedHost: ExtendedHost | undefined
) {
    cachedEnhancedHost = {
        nativeVersion: nativeVersion || getQueryStringParameter('nativeVersion'),
        sessionId: sessionId || getSessionId(),
        deviceId,
        extendedHost: extendedHost || undefined,
    };
}

import { fetch } from 'owa-fetch';
import { getSupportUrl } from './getSupportUrl';

export async function isSupportReachable(): Promise<boolean> {
    const url = getSupportUrl();
    try {
        await fetch(url, { method: 'GET', mode: 'no-cors' });
    } catch (e) {
        // In no-cors mode, since the response is opaque, fetch throws an exception for a non-200 response.
        // So, we don't need to check the response status explicitly.
        return false;
    }

    return true;
}

import { logGreyErrorFromAccounts } from 'owa-account-analytics';
import assert from 'owa-assert/lib/assert';

import {
    accountRankTypeChecker,
    accountSourceDataTypeChecker,
} from '../store/schema/AccountSourceList';
import getAccountSourceListStore from '../store/accountSourceListStore';

import type {
    AccountSource,
    AdditionalAccountInfo,
    M365UserMailboxAccountSource,
    LoadState,
} from '../store/schema/AccountSourceList';
import {
    addSourceToSourcesBySourceIdMap,
    removeSourceFromSourcesBySourceIdMap,
} from './sourcesBySourceIdMapUtils';
import { updateMailboxInfo } from './updateMailboxInfo';

/**
 * Checks the specified account sources to confirm that none of the entries contain
 * duplicate sourceIds
 * @param infoType description of the type of sources being added
 * @param additionalSources Additional sources being added
 */
function checkForDuplicateSourceIds(infoType: string, additionalSources: AccountSource[]) {
    const duplicateSourceIds: string[] = [];
    const existingSourceIds = new Set<string>();
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    additionalSources.forEach(account => {
        if (existingSourceIds.has(account.sourceId)) {
            duplicateSourceIds.push(account.sourceId);
        } else {
            existingSourceIds.add(account.sourceId);
        }
    });

    if (duplicateSourceIds.length > 0) {
        // infoType is a non EUII description of the information being added
        const errorMessage = 'AcctAddSources-DuplicateSourceIdsFound';
        const e = new Error(errorMessage);
        const uniqueDuplicateIds = new Set<string>(duplicateSourceIds);
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logGreyErrorFromAccounts(errorMessage, e, {
            type: infoType,
            total: additionalSources.length,
            dups: duplicateSourceIds.length,
            udups: uniqueDuplicateIds.size,
        });
    }
}

/**
 * This can be called within a mutator action to set the additional sources for an M365 user account
 **/
export default function setM365UserMailboxAdditionalSources(
    infoType: string,
    coprincipalSourceId: string,
    additionalInfo: AdditionalAccountInfo,
    getExisting: (account: M365UserMailboxAccountSource) => AdditionalAccountInfo,
    updateAdditionalAccount: (
        account: M365UserMailboxAccountSource,
        loadState: LoadState,
        sources: AccountSource[]
    ) => void
): void {
    // The additional info must be for the Coprincipal account we are modifying
    assert(
        coprincipalSourceId === additionalInfo.coprincipalSourceId,
        'Additional info must have the same Coprincipal sourceId'
    );

    // We need to update the object in the store not the one in the map
    const filtered = getAccountSourceListStore().sources.filter(
        account => account.sourceId === coprincipalSourceId
    );

    assert(filtered.length === 1, 'Cannot add additional info to a source not in the store');

    const coprincipalAccount = filtered[0];
    assert(
        coprincipalAccount &&
            accountRankTypeChecker.isCoprincipal(coprincipalAccount) &&
            accountSourceDataTypeChecker.isM365UserMailbox(coprincipalAccount),
        `We are attempting to set ${infoType} Account Info to a Non-M365UserMailboxAccountSource`
    );

    const userMailboxAccount = coprincipalAccount;

    // If there are any existing sources we need to remove them from the map of sourceIds
    getExisting(userMailboxAccount).sources.forEach(account => {
        if (getAccountSourceListStore().sourcesBySourceId.has(account.sourceId)) {
            removeSourceFromSourcesBySourceIdMap(account.sourceId);
        } else {
            // We can get here because manually added and automapped shared mailboxes can
            // have duplicate sourceIds. Fixing this issue is tracked by "Task 325041: Unique
            // sourceId for automapped vs manually added shared mailboxes".
            logGreyErrorFromAccounts('DuplicateSourceId-AdditionalSources');
        }
    });

    // Make sure the MailboxInfo data comes from the M365 mailbox information
    additionalInfo.sources = additionalInfo.sources.map(account => updateMailboxInfo(account));

    updateAdditionalAccount(userMailboxAccount, additionalInfo.loadState, additionalInfo.sources);

    // Check for and log any duplicate sourceId values
    checkForDuplicateSourceIds(infoType, additionalInfo.sources);

    // Add all of the newly added sources to the map of sourceIds, if there are any duplicate
    // sourceIds in the list this method will log the error
    const addedAdditionalIndo = getExisting(userMailboxAccount);
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    addedAdditionalIndo.sources.forEach(account => {
        addSourceToSourcesBySourceIdMap(account, coprincipalSourceId);
    });
}

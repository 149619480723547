import { defaultIndexerValue } from './getIndexerValueForMailboxInfo';

export default function isSameCoprincipalAccountIndexers(
    mailbox1Index: string | null,
    mailbox2Index: string | null
): boolean {
    let isSame = false;

    if (
        mailbox1Index &&
        mailbox2Index &&
        mailbox1Index !== defaultIndexerValue &&
        mailbox1Index !== '' &&
        mailbox2Index !== defaultIndexerValue &&
        mailbox2Index !== ''
    ) {
        isSame = mailbox1Index === mailbox2Index;
    }

    return isSame;
}

import { getConfig } from './config';
import type { GovernPriority } from 'owa-client-types/lib/GovernPriority';

let callbacks: (() => void)[] = [];
let unblocked = false;
export function runAfterInitialRender(
    callback: () => Promise<void>,
    governPriority: GovernPriority | undefined,
    addToFront?: boolean,
    name?: string
) {
    const cb =
        governPriority != undefined
            ? () => {
                  // we check for govern here because some modules are loaded early enough that govern is not yet available
                  const { govern } = getConfig();
                  return govern
                      ? govern({
                            task: callback,
                            priority: governPriority,
                            name: `LazyModuleGovern${name ?? 'Unknown'}`,
                        })
                      : callback();
              }
            : callback;
    if (unblocked) {
        cb();
    } else if (addToFront) {
        callbacks.unshift(cb);
    } else {
        callbacks.push(cb);
    }
}

export function unblockLazyLoadCallbacks() {
    unblocked = true;
    for (var ii = 0; ii < callbacks.length; ii++) {
        callbacks[ii]();
    }
    callbacks = [];
}

export function blockLazyLoadCallbacks() {
    unblocked = false;
}

import { fetchLocstrings } from './fetchLocstrings';
import { getItem, setItem } from 'owa-local-storage';
import { getApp } from 'owa-config';

const PRELOAD_STRINGS_STORAGE_KEY_SUFFIX = 'OwaPreloadStrings';
let preloadUrls: string[] | undefined = [];

export async function preloadLocStrings(source: string) {
    if (process.env.NODE_ENV !== 'dev') {
        try {
            const urlsString = getItem(self, `${getApp()}${PRELOAD_STRINGS_STORAGE_KEY_SUFFIX}`);
            if (urlsString) {
                const urls: string[] = JSON.parse(urlsString);

                await Promise.all(urls.map(url => fetchLocstrings(url, source)));
            }
        } catch {
            // nothing should handle if we fail
        }
    }
}

export function addPreloadUrl(url: string): void {
    if (preloadUrls) {
        preloadUrls.push(url);
    }
}

export function cachePreloadUrls(): void {
    setItem(self, `${getApp()}${PRELOAD_STRINGS_STORAGE_KEY_SUFFIX}`, JSON.stringify(preloadUrls));

    // clear preload urls so we stop adding to the array to avoid a memory leak
    preloadUrls = undefined;
}

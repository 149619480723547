import { isBootFeatureEnabled } from 'owa-metatags';

let workerScriptTrustedTypesPolicy: Pick<TrustedTypePolicy, 'createScriptURL'> | undefined;
if (window.trustedTypes) {
    workerScriptTrustedTypesPolicy = window.trustedTypes.createPolicy(
        'workerScriptTrustedTypesPolicy',
        {
            createScriptURL: (url: string) => {
                const regex = new RegExp(
                    `^blob:${window.location.origin}/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$`
                );

                if (
                    !isBootFeatureEnabled('fwk-worker-script-trusted-types-policy') ||
                    regex.test(url)
                ) {
                    return url;
                }
                // The URL is not allowed, return an empty string
                return '';
            },
        }
    );
}

export { workerScriptTrustedTypesPolicy };

import type { MailboxInfo } from 'owa-client-types';
import type { ApplicationSettingGroup, ApplicationSettings } from 'owa-application-settings';

export function shouldShadowCallNetCore(
    getApplicationSettingsCallback?: <TGroup extends ApplicationSettingGroup>(
        group: TGroup,
        mailboxInfo?: MailboxInfo,
        dontErrorIfNotInitialized?: boolean
    ) => ApplicationSettings[TGroup],
    mailboxInfo?: MailboxInfo
) {
    const setting = getApplicationSettingsCallback?.('NetCore', mailboxInfo);
    return !!setting?.shouldShadowCall;
}

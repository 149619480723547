import type MailboxInfo from './MailboxInfo';
import getIndexerValueForMailboxInfo from './getIndexerValueForMailboxInfo';
import isSameCoprincipalAccountIndexers from './isSameCoprincipalAccountIndexers';

export default function isSameCoprincipalAccountMailboxAndIndexer(
    mailbox1Info: MailboxInfo | null,
    mailbox2Index: string | null
): boolean {
    let isSame = false;

    if (mailbox1Info) {
        const mailbox1Index = getIndexerValueForMailboxInfo(mailbox1Info);

        isSame = isSameCoprincipalAccountIndexers(mailbox1Index, mailbox2Index);
    }

    return isSame;
}

import { setItem, removeItem } from '../localStorageAdapter';
import { type LocalStorageKeys } from './LocalStorageKeys';

// Wrap "setItem" and lock "window" object to the one is avaialble on main thread and has access to actual LocalStorage
// This function is passed as a callback to Web Worker thread from main thread.
// And then on worker thread we save it into "localStorageSetItemCb" variable by calling "setLocalStorageCallbacks".
export function setItemCb(key: LocalStorageKeys, value: string) {
    setItem(self, key, value);
}

// Wrap "removeItem" and lock "window" object to the one is avaialble on main thread and has access to actual LocalStorage
// This function is passed as a callback to Web Worker thread from main thread.
// And then on worker thread we save it into "localStorageSetItemCb" variable by calling "setLocalStorageCallbacks".
export function removeItemCb(key: LocalStorageKeys) {
    removeItem(self, key);
}

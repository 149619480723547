/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-local-storage' import is restricted from being used. localStorage is not available in the analytics worker [analytics-worker-lint] */
import { setItem, getItem } from 'owa-local-storage';
import type { LogicalRingEnum } from 'owa-client-pie/lib/outlookwebcontext.g';

const LogicalRingKey = 'LogicalRing';
export type LogicalRing = keyof typeof LogicalRingEnum;
let logicalRing: LogicalRing | null;

export function setLogicalRingForNonPrimaryAccount(ring: LogicalRing, mailboxSmtpAddress: string) {
    if (!!logicalRing && logicalRing === ring) {
        return;
    }
    logicalRing = ring;
    setItem(self, `${LogicalRingKey}_${mailboxSmtpAddress}`, ring);
}

export function getLogicalRingForNonPrimaryAccount(mailboxSmtpAddress: string): LogicalRing | null {
    if (!logicalRing) {
        logicalRing = getItem(
            self,
            `${LogicalRingKey}_${mailboxSmtpAddress}`
        ) as LogicalRing | null;
    }

    return logicalRing;
}

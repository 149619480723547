import { isMsalEnabledForBusiness } from './isMsalEnabledForBusiness';
import { isMsalEnabledForConsumerDomain } from './isMsalEnabledForConsumerDomain';
import { isMonitoringProbe } from './isMonitoringProbe';
import { getItem as sessionStorageGetItem } from 'owa-session-storage';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { PENDING_REDIRECT_KEY } from './utils/constants';

// Checks whether the Msal flow is enabled for the current user.
export function isMsalFlowEnabled(): boolean {
    return (
        isMsalEnabledForConsumerDomain() ||
        isMsalEnabledForBusiness() ||
        isMonitoringProbe() ||
        pendingRedirect()
    );
}

/**
 * Checks whether the current page session just came from an MSAL redirect. If true,
 * MSAL flow should be enabled to allow it to handle the incoming redirect and redirect
 * back to the proper start page. This is to allow scenarios where the boot flight is missing
 * on the interstitial redirect between the initial load and the final redirect
 * @returns true if current page load is an MSAL redirect
 */
function pendingRedirect(): boolean {
    return (
        isHostAppFeatureEnabled('msalTokenFetch') &&
        (sessionStorageGetItem(self, PENDING_REDIRECT_KEY) as string) === '1' &&
        self.location.hash.indexOf('=') > 0
    );
}

// Polyfills are placed here to get imported before any bootstrapping code runs
//
// NOTE: NEVER add a polyfill that is not part of a spec implemented at least in all the modern browsers (Edge, Chrome, Firefox)

// we need these polyfills because of Safari
import 'raf/polyfill';
import 'intersection-observer';
// Reflect.hasOwnMetadata is used at runtime in decorators in esbuild
//
// Normally this isn't needed, and is handled by typescript
// transpilation at compile-time.
//
// However, when running in esbuild, this isn't handled for us, so we require
// this polyfill manually here.
//
// This polyfill dev check was removed to fix the issue with importing @editor/client-textutils SentenceSeparator
// which is using injectable decorator from inversify
// https://github.com/inversify/InversifyJS/blob/c68e60dc410485956cd21c345ed6f3b4ed4d5abb/src/annotation/injectable.ts#L9
require('reflect-metadata');

import type { WindowData } from 'owa-window-data';
import { getWindowData } from 'owa-window-data';
import { type LocalStorageKeys } from './util/LocalStorageKeys';

const owaPrefix = 'olk-';

export function getOwaPrefixedKey(key: string): string {
    return owaPrefix + key;
}

/* eslint-disable no-restricted-properties -- (https://aka.ms/OWALintWiki)
 * This wrapper is provider so everyone has to come through owa-local-storage */

export function setItem(windowObj: Window, key: LocalStorageKeys, value: string): void {
    if (localStorageExists(windowObj)) {
        try {
            const actualKey = owaPrefix + key;
            const actualOldKey = key;
            localStorage.setItem(actualKey, value);
            windowObj.localStorage.setItem(actualKey, value);

            // This should be removed once local storage namespace is fully migrated.
            // Essential account data is stored in local storage. Removing this could be a breaking change if done too soon.
            windowObj.localStorage.removeItem(actualOldKey);
        } catch {
            // suppress quota exception
        }
    } else {
        // window object (or self) doesn't have access to LocalStorage on Web Worker thread
        // So we have to get a mocked version of it from owa-window-data in-memory storage
        const windowData = getWindowData();
        // "windowData?.localStorage?.setItem" on Web Worker thread refers to
        // "setItem" function defined here, but on the main thread.
        // We don't apply "olk-" prefix logic on Web Worker thread as it will be added on the main thread
        windowData?.localStorage?.setItem(key, value);
    }
}

export function getItem(windowObj: Window, key: LocalStorageKeys): string | null {
    const enabledPrefixKey = owaPrefix + key;
    const disabledPrefixKey = key;

    if (localStorageExists(windowObj)) {
        try {
            return (
                windowObj.localStorage.getItem(enabledPrefixKey) ||
                windowObj.localStorage.getItem(disabledPrefixKey)
            );
        } catch {
            return null;
        }
    } else {
        // window object (or self) doesn't have access to LocalStorage on Web Worker thread
        // So we have to get a mocked version of it from owa-window-data in-memory storage
        const windowData = getWindowData();
        return (
            windowData?.localStorage?.getItem(enabledPrefixKey) ||
            windowData?.localStorage?.getItem(disabledPrefixKey) ||
            null
        );
    }
}

export function removeItem(windowObj: Window, key: LocalStorageKeys): void {
    if (localStorageExists(windowObj)) {
        try {
            windowObj.localStorage.getItem(key)
                ? windowObj.localStorage.removeItem(key)
                : windowObj.localStorage.removeItem(owaPrefix + key);
        } catch {
            // suppress exception
        }
    } else {
        // window object (or self) doesn't have access to LocalStorage on Web Worker thread
        // So we have to get a mocked version of it from owa-window-data in-memory storage
        const windowData = getWindowData();
        // "windowData?.localStorage?.removeItem" on Web Worker thread refers to
        // "removeItem" function defined here, but on the main thread.
        return windowData?.localStorage?.removeItem(key);
    }
}

export function getNumberOfItems(windowObj: Window): number {
    if (localStorageExists(windowObj)) {
        try {
            return windowObj.localStorage.length;
        } catch {
            // suppress exception
        }
    }

    return 0;
}

export function getKey(windowObj: Window, index: number): LocalStorageKeys | null {
    if (localStorageExists(windowObj)) {
        try {
            const regex = RegExp('^' + owaPrefix);
            const key = windowObj.localStorage.key(index);
            if (key) {
                return key?.replace(regex, '') as LocalStorageKeys;
            }
        } catch {
            // suppress exception
        }
    }

    return null;
}

export function itemExists(windowObj: Window, key: LocalStorageKeys): boolean {
    return (
        (getItem(windowObj, key) || getItem(windowObj, `owaPrefix${key}` as LocalStorageKeys)) !==
        null
    );
}

export function getLocalStorage(windowObj: Window): Storage {
    return windowObj.localStorage;
}

export function localStorageExists(windowObj: Window | WindowData): boolean {
    try {
        return windowObj && !!windowObj.localStorage;
    } catch {
        return false;
    }
}

//If owaPrefix is ever updated, this function can be called to replace the old prefix that was used.
export function updateOldPrefix(windowObj: Window, oldPrefix: string): void {
    if (localStorageExists(windowObj)) {
        const regex = RegExp('^' + oldPrefix);
        for (let i = 0; i < windowObj.localStorage.length; i++) {
            var currentKey = windowObj.localStorage.key(i);
            if (currentKey?.startsWith(oldPrefix)) {
                const value = windowObj.localStorage.getItem(currentKey);
                // Check if the value exists before proceeding
                if (value !== null) {
                    const newKey = currentKey.replace(regex, '');
                    setItem(windowObj, newKey as LocalStorageKeys, value);
                    removeItem(windowObj, currentKey as LocalStorageKeys);
                }
            }
        }
    }
}

export function getPrefix(): string {
    return owaPrefix;
}

/* eslint-enable no-restricted-properties */

import { owaNetCoreEqualityCheck } from './utils/owaNetCoreEqualityCheck';

export const owaNetCoreComparator: {
    [key: string]: (owaResponse: any, netcoreResponse: any, actionName: string) => any;
} = {};

for (const item of getCompareDefinitions()) {
    for (const action of item.actionNames) {
        owaNetCoreComparator[action] = item.compareFn;
    }
}

interface CompareDefinition {
    compareFn: (owaResponse: any, netcoreResponse: any, actionName: string) => any;
    actionNames: Set<string>;
}

function getCompareDefinitions(): CompareDefinition[] {
    return [
        {
            compareFn: (owaResponse: any, netcoreResponse: any, actionName: string) =>
                owaNetCoreEqualityCheck(
                    owaResponse,
                    netcoreResponse,
                    actionName,
                    false /* matchValue */,
                    true /* matchArrayOrder */
                ),
            actionNames: new Set([
                'GetAccessTokenForResource',
                'GetAccessTokenforDataProvider',
                'GetAccessTokenforResource',
                'GetAttachmentDownloadToken',
                'GetClientAccessToken',
            ]),
        },
        {
            compareFn: (owaResponse: any, netcoreResponse: any, actionName: string) =>
                owaNetCoreEqualityCheck(
                    owaResponse,
                    netcoreResponse,
                    actionName,
                    true /* matchValue */,
                    true /* matchArrayOrder */
                ),
            actionNames: new Set([
                'CheckPendingAttachmentOperation',
                'DownloadMessage',
                'EvaluateSafeLink',
                'FetchDataProviderResource',
                'FindCategoryDetails',
                'FindConversation',
                'FindFolder',
                'FindItem',
                'FindOutlookQuickSteps',
                'FindPlaces',
                'GetAccountInformation',
                'GetActiveRetentionPolicyTags',
                'GetAddIns',
                'GetAllAttachmentDataProviders',
                'GetAllAttachmentsAsZip',
                'GetAllClientExtensionsNotifications',
                'GetAllowedOptions',
                'GetApp',
                'GetAttachment',
                'GetAttachmentDataProviderCreator',
                'GetAttachmentDataProviderItems',
                'GetAttachmentDataProviderUploadFolderProps',
                'GetAttachmentDataProviders',
                'GetAttachmentLabel',
                'GetAttachmentPdfPreview',
                'GetAttachmentPreviews',
                'GetAttachmentTextPreview',
                'GetAttachmentThumbnail',
                'GetAuthenticationUrl',
                'GetAvailableRetentionPolicyTags',
                'GetBposShellInfoNavBarData',
                'GetBposShellInfoNavBarDataForBookings',
                'GetCASMailbox',
                'GetCASMailbox2',
                'GetCertsInfo',
                // 'GetConversationItems', // Removed due to throttling and transiency
                'GetComplianceConfiguration',
                'GetConsumerMailbox',
                'GetDlpPolicyTips',
                'GetEncryptionCerts',
                'GetExtensibilityContext',
                'GetFavorites',
                'GetFileAttachment',
                'GetFileItems',
                'GetFocusedInboxConfiguration',
                'GetFocusedOtherOverrides',
                'GetFolder',
                'GetFolderChangeDigest',
                'GetFolderMruConfiguration',
                'GetFullSearchHistoryFromRest',
                'GetInboxRule',
                //'GetItem', // Removed due to throttling and transiency
                'GetLinkPreview',
                'GetMailTips',
                'GetMailboxAutoReplyConfiguration',
                'GetMailboxByIdentity',
                'GetMailboxJunkEmailConfiguration',
                'GetMailboxMessageConfiguration',
                'GetMailboxPermission',
                'GetMailboxRegionalConfiguration',
                'GetMessageClassification',
                'GetMime',
                'GetMrsConnectedAccounts',
                'GetNewAttachmentDataProviderCreationInfo',
                'GetNextMeetingWithPerson',
                'GetOptionSummary',
                'GetOtherMailboxConfiguration',
                'GetOutlookDotComMigrationDiagnostics',
                'GetOwaNonBootUserConfiguration',
                'GetOwaUserConfiguration',
                'GetParcelDeliveryStatus',
                'GetReactFlightsAndSettings',
                'GetRegionalConfiguration',
                'GetRoomLists',
                'GetRoomsInternal',
                'GetSearchSuggestions',
                'GetSharePointTextFileContent',
                'GetSharingInfo',
                'GetSharingInformation',
                'GetSocialActivityNotifications',
                'GetSocialActivityNotifications2',
                'GetSweepRules',
                'GetTaskFolders',
                'GetThemes',
                'GetTimeZone',
                'GetTimeZoneOffsets',
                'GetUcwaUserConfiguration',
                'GetUserConfiguration',
                'GetUserContext',
                'GetUserOofSettings',
                'GetUserRetentionPolicyTags',
                'GetUserVoiceData',
                'GetWacAttachmentInfo',
                'StartupData',
                // Group APIs
                'GetUserUnifiedGroups',
                'GetUnifiedGroupDetails',
                'GetDlMembersForUnifiedGroup',
                'GetSuggestedUnifiedGroups',
                'GetUnifiedGroupMembers',
                'GetUnifiedGroupMembershipRequests',
                'GetUnifiedGroupsSettings',
                'GetUnifiedGroupPrivacyTaskCommand',
                'FindUnifiedGroups',
                'SuggestUnifiedGroupMembers',
                // Times API
                'GetBirthdayCalendarView',
                'GetBirthdayEvent',
                'GetCalendarEvent',
                'GetCalendarFeeds',
                'GetCalendarFolderConfiguration',
                'GetCalendarFolders',
                'GetCalendarProcessing',
                'GetCalendarSharingPermissions',
                'GetCalendarView',
                'GetHolidayCalendars',
                'GetMailboxCalendarConfiguration',
                'GetReminders',
                'GetSharingPermissionInfo',
                'GetSharingPermissions',
                'GetUserAvailabilityInternal',
                'SetCalendarColor',
                'SetCalendarHexColor',
                'GetBookingStaff',
                'GetBookingMailboxDiagnostics',
                'GetPersonalSchedulingPage',
                'GetBookingStatistics',
                'GetBookingCalendarPublishing',
                // Contact APIs
                'GetPersona',
                'GetPersonaPhoto',
                'GetNotesForPersona',
                'GetGroupInfo',
                'GetPersonaSuggestions',
                'GetPeopleFilters',
                'MaskAutoCompleteRecipient',
                'FindPeople',
                'GetUserPhoto',
                'GetPersonalListsMapping',
                'GetPersonaOrganizationHierarchy',
            ]),
        },
    ];
}

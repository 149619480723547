import { type HttpStatusCode } from 'owa-http-status-codes';
import { type HeadersWithoutIterator } from './RequestOptions';
import { getConfig } from './config';

const activityTimeoutErrorMessage: string = 'the logon session timed out';

export default function isActivityTimeoutAuthError(
    status: number,
    headers?: HeadersWithoutIterator
): boolean {
    return (
        status == 440 &&
        isActivityTimeoutHeaderPresent(headers) &&
        !!getConfig()?.isMsalFlowEnabled &&
        getConfig()?.onActivityTimeoutError != undefined
    );
}

function isActivityTimeoutHeaderPresent(headers?: HeadersWithoutIterator) {
    var diagnosticsHeader = headers?.get('x-ms-diagnostics')?.toLowerCase();
    return (
        diagnosticsHeader !== undefined &&
        diagnosticsHeader.indexOf(activityTimeoutErrorMessage) > -1
    );
}

import type { MailboxInfo } from 'owa-client-types';
import { isBootFeatureEnabled } from 'owa-metatags';
import { type ApplicationSettingGroup, type ApplicationSettings } from 'owa-application-settings';

export function isOwaNetCoreApiEnabled(
    actionName: string,
    getApplicationSettingsCallback?: <TGroup extends ApplicationSettingGroup>(
        group: TGroup,
        mailboxInfo?: MailboxInfo,
        dontErrorIfNotInitialized?: boolean
    ) => ApplicationSettings[TGroup],
    mailboxInfo?: MailboxInfo
) {
    return (
        (isBootFeatureEnabled('fwk-netcore-api') && actionName == 'StartupData') ||
        isNetCoreAPI(actionName, getApplicationSettingsCallback, mailboxInfo)
    );
}

function isNetCoreAPI(
    actionName: string,
    getApplicationSettingsCallback?: <TGroup extends ApplicationSettingGroup>(
        group: TGroup,
        mailboxInfo?: MailboxInfo,
        dontErrorIfNotInitialized?: boolean
    ) => ApplicationSettings[TGroup],
    mailboxInfo?: MailboxInfo
) {
    const setting = getApplicationSettingsCallback?.(
        'NetCore',
        mailboxInfo,
        true /* dontErrorIfNotInitialized */
    );
    return (
        !setting?.disabledAPIs.includes(actionName) &&
        (setting?.enabledAPIs.includes(actionName) || setting?.enabledAPIs.includes('*'))
    );
}

import type { ExtendedWorkerOptions } from 'owa-analytics-types';
import { getScriptPath, getScriptBackupPath } from 'owa-config';
import { workerTemplate } from './utils/workerTemplate';
import { postMessageWrapper } from 'owa-worker-wrapper';
import { isBootFeatureEnabled } from 'owa-metatags/lib/isBootFeatureEnabled';
import { workerScriptTrustedTypesPolicy } from 'owa-content-security-policies/lib/trustedTypesPolicies/workerScriptTrustedTypesPolicy';

// Analytics' `initializeInWebWorker` relies on runtime failure to fallback to main thread
// Safari doesn't have Worker constructor in WorkerGlobalScope, so it will throw on import if you extend Worker

// Adapted from https://github.com/webpack/webpack/discussions/14648#discussioncomment-1589272
// until webpack has native support for cross-origin workers.
class OwaWorker {
    constructor(scriptName: string, options?: ExtendedWorkerOptions) {
        const scriptPath = getScriptPath();
        const backupScriptPath = getScriptBackupPath();
        const workerAsyncLoad = isBootFeatureEnabled('workerAsyncLoad');
        const objectURL = URL.createObjectURL(
            new Blob(
                [
                    `(${workerTemplate.toString()})("${scriptName}","${scriptPath}","${backupScriptPath}", ${workerAsyncLoad});`,
                ],
                {
                    type: 'application/javascript',
                }
            )
        );

        const worker = new Worker(
            // typescript doesn't know about trusted types :( so we need to cast it
            (workerScriptTrustedTypesPolicy?.createScriptURL(objectURL) as unknown as
                | string
                | undefined) ?? objectURL,
            {
                name: scriptName,
                ...options,
            }
        );
        URL.revokeObjectURL(objectURL);

        // Wrap the worker to add analytics
        if (options) {
            wrapWorkerForAnalytics(worker, options);
        }

        return worker;
    }
}

function wrapWorkerForAnalytics(worker: OwaWorker, workerOptions: ExtendedWorkerOptions) {
    const workerAsWorker = worker as Worker;
    const pPostMessage = workerAsWorker.postMessage;

    workerAsWorker.postMessage = function (
        message: any,
        options?: Transferable[] | StructuredSerializeOptions
    ) {
        postMessageWrapper(
            workerAsWorker,
            pPostMessage,
            message,
            options,
            workerOptions.workerName
        );
    };
}

// Fix for proper typechecking
export default OwaWorker;
